import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import AnimatedImage from 'components/image/animated-image'
import Subscribe from 'components/footer/subscribe'
import styles from './cta.module.css'

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "footer-image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 750, maxWidth: 2500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wordpressAcfOptions {
      options {
        enable_subscribe
      }
    }
  }
`

const CallToAction = () => {
  const data = useStaticQuery(query);
  const image = data.placeholderImage.childImageSharp.fluid;
  const { enable_subscribe } = data.wordpressAcfOptions.options

  return (
    <div className={styles.container}>
      <AnimatedImage image={image} className={styles.image} />
      <Subscribe enabled={enable_subscribe} />
    </div>
  )
}

export default CallToAction