import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import classnames from 'classnames'

import styles from './navmenu.module.css'

const query = graphql`
  query FooterMenu {
    allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "primary-menu"}}) {
      edges {
        node {
          items {
            order
            title
            url
            attr
            target
            classes
            object_slug
          }
        }
      }
    }
  }
`

const FooterNavItem = ({ object_slug, title }) => {
  // eslint-disable-next-line

  return (
    <li className={styles.menuItem}>
      <AniLink 
        fade
        to={`/${object_slug}`} 
        className={classnames(styles.menuLink, 'footer-link', 'underline-animation', 'white')}
      
      >
        { title }
      </AniLink>
    </li>
  )
}

const FooterNavDecorator = ({ children }) => <li className={styles.menuItem}>{ children }</li>

const FooterNav = () => {
  const data = useStaticQuery(query);
  const { items } = data.allWordpressWpApiMenusMenusItems.edges[0].node
  const modifiedItems = [].concat(...items.map(e => ['.:.', e])).slice(1)
  const menuItems = modifiedItems.map(item => {
    if (typeof item === 'string') {
      return <FooterNavDecorator key={Math.random()}>{ item }</FooterNavDecorator>
    } else {
      return <FooterNavItem key={item.object_slug} {...item} />
    }
  })

  return (
    <ul className={styles.menu}>
      { menuItems }
    </ul>
  )
}

export default FooterNav