import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import MobileDetect from 'mobile-detect'

import CallToAction from 'components/footer/cta'
import FooterNav from 'components/footer/navmenu'
// import FooterCanvas from 'components/footer/canvas'
import styles from './footer.module.css'

const query = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wordpressAcfOptions {
      id
      options {
        email_address
        phone_number
        seo_locality_text
        address {
          address_1
          address_2
          city
          state
          zip
        }
        social_media_links {
          icon
          link {
            url
          }
        }
      }
    }
  }
`

const LocalityLink = ({ to, children }) => {
  return (
    <AniLink
      fade
      to={to}
      className={classnames(
        styles.localityLink,
        'locality-link',
        'underline-animation'
      )}
    >
      {children}
    </AniLink>
  )
}

const SocialMediaLink = ({ icon, url }) => {
  return (
    <a
      href={url}
      target='_blank'
      rel='noreferrer noopener'
    >
      <FontAwesomeIcon
        icon={[ 'fab', icon ]}
        className={styles.icon}
      />
    </a>
  )
}

const Footer = () => {
  const data = useStaticQuery(query);
  const backgroundImage = data.backgroundImage.childImageSharp.fluid;
  const { email_address, phone_number, seo_locality_text, address, social_media_links } = data.wordpressAcfOptions.options
  const userAgent = typeof window !== 'undefined' ?  window.navigator.userAgent.toString() : ''
  const md = new MobileDetect(userAgent)

  const socialMedia = social_media_links.map((item, index) => {
    return <SocialMediaLink icon={item.icon} key={index} url={item.link.url} />
  })

  return (
    <footer className={styles.container}>
      <Img fluid={backgroundImage} className={styles.backgroundImage} />
      <CallToAction />
      <FooterNav />

      <div className={classnames(styles.locality, md.tablet() && styles.isTablet)}>
        <div className={styles.seo}>
          <p>{ seo_locality_text }</p>
        </div>

        <div className={styles.contact}>
          <div className={styles.contactInfo}>
            <address>{ `${address.address_1}, ${address.city}, ${address.state} ${address.zip}` }</address>
            <span className='contact-module'>|</span>
            <span className='contact-module'>
              { 'T.\u00A0' }
              <a href={`tel:${phone_number}`} className='underline-animation'>
                (240) 755-0906
              </a>
            </span>

            <span className='contact-module'>|</span>

            <span className='contact-module'>
              { 'E.\u00A0' }
              <a href={`mailto:${email_address}`} className='underline-animation'>
                { email_address }
              </a>
            </span>
          </div>

          <div className={styles.contactInfo}>
            <span className='contact-module' style={{ fontWeight: 400 }}>
              { `© ${new Date().getFullYear()}` }
              { '\u00A0' }
              <a href={'/'} className='underline-animation'>Array Dental Group</a>
            </span>

            <span className='contact-module'>|</span>

            <span className='contact-module' style={{ fontWeight: 400 }}>
              { `Design by` }
              { '\u00A0' }
              <a href={'https://compulse.com'} target='_blank' rel='noopener noreferrer' className='underline-animation'>Compulse</a>
            </span>
          </div>
        </div>

        <div className={styles.links}>
          { socialMedia }

          <LocalityLink to='/careers'>Careers</LocalityLink>
          <span>{ '|' }</span>
          <LocalityLink to='/sitemap'>Sitemap</LocalityLink>
        </div>
      </div>
    </footer>
  )
}

export default Footer