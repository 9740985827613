import React, { useRef, useState, useEffect } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import classnames from 'classnames'
import { TweenMax, Power2 } from 'gsap'
import VisibilitySensor from 'react-visibility-sensor'

import styles from './animated-image.module.css'

const AnimatedImage = ({ image, className }) => {
  const imageRef = useRef(null)
  const [cached, setCached] = useState(false)
  const [visible, setVisibility] = useState(false)
  const [enabled, setEnabled] = useState(true)

  const animateImage = (delay = 0) => {
    const image = imageRef.current.imageRef.current

    if (image) {
      TweenMax.to(image, 1.7, { scaleX: 1, scaleY: 1, x: 0, y: 0, z: 0, opacity: 1, ease: Power2.easeOut, delay: 0.15 + delay})
    }
  }

  useEffect(() => {
    setEnabled(true)
  }, [])

  const onChange = (isVisible) => {
    if (!visible && isVisible && enabled) {
      setVisibility(isVisible)
      animateImage(-0.15)
    }
  }

  return (
    <VisibilitySensor onChange={onChange} partialVisibility active={!visible}>
      <Img 
        fluid={image.fluid ? image.fluid : image} 
        alt={image.alt_text}
        fadeIn={true}
        className={classnames(styles.image, className)} 
        ref={imageRef}
        objectFit='cover'

        onLoad={() => {
          const image = imageRef.current.imageRef.current
          TweenMax.set(image, { scaleX: 1.2, scale: 1.2 })

          const rect = imageRef.current.imageRef.current.getBoundingClientRect()
          
          if (rect.top <= window.innerHeight) {
            setVisibility(true)
            animateImage()
          }
        }}

        onStartLoad={({ wasCached }) => {
          setCached(wasCached)
        }}
      />
    </VisibilitySensor>
  )
}

export default AnimatedImage