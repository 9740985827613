import React, { useRef, useState, useEffect } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TimelineMax, Power2 } from 'gsap'
import classnames from 'classnames'

import styles from './animated-button.module.css'

const AnimatedButton = ({ to = '/', message = 'Learn More', light = false, className = '', dark = false, mailTo = '' }) => {
  const [hovering, setHovering] = useState(false)
  const [animation, setAnimation] = useState(null)
  const containerRef = useRef(null)
  const iconRef = useRef(null)
  const bgRef = useRef(null)
  const textRef = useRef(null)
  const lineRef = useRef(null)

  // Make sure that the final forward slash is removed
  // let modifiedTo = to

  // if (modifiedTo.length > 1) {
  //   if (modifiedTo.substring(modifiedTo.length - 1) === '/') {
  //     modifiedTo = modifiedTo.substring(0, modifiedTo.length - 1)
  //   }
  // }

  // Set the styles of the button in Light or Dark
  let linkStyle = styles.link
  let lineStyle = styles.line
  let backgroundStyle =styles.background

  if (light) {
    linkStyle = styles.linkLight
    lineStyle = styles.lineLight
    backgroundStyle = styles.backgroundLight
  }

  if (dark) {
    linkStyle = styles.linkDark
    lineStyle = styles.lineDark
    backgroundStyle = styles.backgroundDark
  }

  useEffect(() => {
    const width = containerRef.current.offsetWidth
    const a = new TimelineMax({ paused: true })
    let color = '#ffffff'
    
    if (light) color = '#4a8d9c'

    a.to(iconRef.current, 0.25, { opacity: 0.1, x: width + 5, y: '-50%', ease: Power2.easeInOut }, 0)
    a.to(lineRef.current, 0.25, { x: 5, ease: Power2.easeInOut }, 0)
    a.to(bgRef.current, 0.15, { scaleY: 1, ease: Power2.easeOut }, 0.2)
    a.to(textRef.current, 0.15, { color, ease: Power2.easeInOut }, 0.2)

    setAnimation(a)
  }, [])
  
  useEffect(() => {
    if (animation) {
      if (hovering) {
        animation.play()
      } else {
        animation.reverse()
      }
    }
  }, [hovering])

  if (mailTo) {
    return (
      <a href={`mailto:${mailTo}`} className={styles.wrapper}>
        <div className={classnames(linkStyle, className)} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} ref={containerRef}>
          <span className={backgroundStyle} ref={bgRef}></span>
          <span className={lineStyle} ref={lineRef}></span>
          <span ref={iconRef} className={styles.iconWrapper}><FontAwesomeIcon icon={['far', 'arrow-right']} className={styles.linkIcon} /></span>
          <span className={styles.content} ref={textRef} dangerouslySetInnerHTML={{ __html: message }}></span>
        </div>
      </a>
    )
  } else {
    return (
      <AniLink fade to={to} className={styles.wrapper}>
        <div className={classnames(linkStyle, className)} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} ref={containerRef}>
          <span className={backgroundStyle} ref={bgRef}></span>
          <span className={lineStyle} ref={lineRef}></span>
          <span ref={iconRef} className={styles.iconWrapper}><FontAwesomeIcon icon={['far', 'arrow-right']} className={styles.linkIcon} /></span>
          <span className={styles.content} ref={textRef} dangerouslySetInnerHTML={{ __html: message }}></span>
        </div>
      </AniLink>
    )
  }
}

export default AnimatedButton