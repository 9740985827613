import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './subscribe.module.css'

const AccentBlock = () => <span className={styles.accent}></span>

const Subscribe = ({ enabled = true, title = 'Stay in the Know' }) => {
  // eslint-disable-next-line
  const [email, setEmail] = useState('')

  const handleInputChange = e => {
    const { value } = e.target
    setEmail(value)
  }
  
  return (
    <div className={styles.container}>
      <h3>{ title }</h3>
      <AccentBlock />
      
      {
        enabled
        ? <form className={styles.formContainer}>
          <input 
            className={styles.input}
            type='email' 
            onChange={handleInputChange} 
            placeholder={'enter your email address to subscribe'} 
          />
          <FontAwesomeIcon icon={['fal', 'paper-plane']} className={styles.icon} />
        </form>
        : <p>Email us at: <a href='mailto:info@arraydentalgroup.com' className='underline-animation'>info@arraydentalgroup.com</a></p>
      }
    </div>
  )
}

export default Subscribe